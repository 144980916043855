import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import * as Buttons from "../components/Buttons"
import * as Containers from "../components/Containers"
import * as Icons from "../components/Icons"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactUsGraphics from "../images/artwork/ContactUs-Graphics_v4.svg"

import Success from "../images/ContactUs-Success_v1.inline.svg"

const SuccessPage = () => {
  // useEffect(() => {
  //   const redirect = setInterval(() => {
  //     //assign interval to a variable to clear it.
  //     navigate("/")
  //   }, 5000)

  //   return () => clearInterval(redirect) //This is important
  // }, [])

  return (
    <Layout>
      <SEO title="Success" />
      <Containers.PageContainer>
        <Containers.SectionContainer
          style={{
            flexDirection: "column",
          }}
        >
          <ContactBg />
          <Header>
            <h1>Thank you for reaching out to us!</h1>
          </Header>
          <FormContainer>
            <FormWrapper>
              <SuccessIcon />
              <TextContainer>
                <p>We got your message and our team is on it!</p>
                <p>
                  Please allow us to review your message we will get back to you
                  within 24 hours. Should you require immediate attention, you
                  can reach us at
                  <a
                    href="mailto:support@uuoni.com"
                    style={{
                      marginLeft: "0.3rem",
                      marginRight: "0.3rem",
                    }}
                  >
                    support@uuoni.com
                  </a>
                  and we will assist you.
                </p>
              </TextContainer>

              {/* <p>You will be redirected shortly!</p> */}
              <ResubmitButton to="/contact">
                Submit another response
                <Icons.RightArrowIcon />
              </ResubmitButton>
              <RedirectButton to="/">Back to Home</RedirectButton>
            </FormWrapper>
          </FormContainer>
        </Containers.SectionContainer>
        <Containers.SectionContainer
          style={{
            flexDirection: "column",
          }}
        ></Containers.SectionContainer>
      </Containers.PageContainer>
    </Layout>
  )
}

export default SuccessPage

const ContactBg = styled.div`
  position: absolute;
  background-image: url(${ContactUsGraphics});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  transform: scale(1.11);
  top: 6%;
  z-index: -10;

  @media screen and (min-width: 1025px) {
    background-position: 100% 34.5%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-size: contain;
    transform: scale(1.15);
    top: -7.5%;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 1025px) {
    width: 40%;
    margin: 0 auto 3rem auto;

    p {
      width: 60%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 70%;
    margin: auto auto 3rem;

    p {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    text-align: left;
    align-items: flex-start;
    width: 90%;
    margin-right: auto;

    h1 {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`

const FormContainer = styled.div`
  background: var(--theme-ui-colors-white, #ffffff);
  box-shadow: 5px 2px 4px rgba(46, 41, 51, 0.08);
  padding: 4px;
  background-repeat: no-repeat;
  background-size: 100% 4px;
  border-radius: 30px;

  @media screen and (min-width: 1025px) {
    width: 75%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media screen and (max-width: 1024px) {
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10%;

  @media screen and (max-width: 767px) {
    padding: 0% 0%;
    padding-top: 5%;
  }
`

const TextContainer = styled.div`
  padding: 5% 20%;
  text-align: center;

  @media screen and (min-width: 1025px) {
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media screen and (max-width: 767px) {
    padding: 5% 1%;
    text-align: left;

    width: 80%;
  }
`
const RedirectButton = styled(Buttons.ButtonLink)`
  margin-bottom: 40px;
  align-self: center;

  @media screen and (min-width: 1025px) {
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }
  @media screen and (max-width: 767px) {
    align-self: flex-start;
  }
`

const ResubmitButton = styled(Buttons.ButtonLinkArrowed)`
  margin-bottom: 40px;
  align-self: center;

  @media screen and (min-width: 1025px) {
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }
  @media screen and (max-width: 767px) {
    align-self: flex-start;
    margin-left: 0;
    padding: 0;
  }
`

const SuccessIcon = styled(Success)`
  align-self: center;
  width: 10rem;
  height: 10rem;

  @media screen and (max-width: 767px) {
    align-self: flex-start;
  }
`
